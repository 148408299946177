<template>
  <svg
    width="7px"
    height="13px"
    viewBox="0 0 7 13"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Client"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="02.01.squadio2-final-design"
        transform="translate(-1182.000000, -4327.000000)"
        fill="#fff"
      >
        <g id="footer" transform="translate(-3.000000, 4203.000000)">
          <g id="Facebook-Icon" transform="translate(1168.000000, 108.000000)">
            <path
              d="M22.0841694,18.0757028 C21.6680882,18.0757028 20.7719135,18.3625886 20.7719135,18.9194845 L20.7719135,20.2526596 L22.9003289,20.2526596 L22.9003289,22.4971188 L20.7719135,22.4971188 L20.7719135,28.6904764 L18.6274952,28.6904764 L18.6274952,22.4971188 L17.5712891,22.4971188 L17.5712891,20.2526596 L18.6274952,20.2526596 L18.6274952,19.1219921 C18.6274952,17.4175531 19.3636387,16 21.1399853,16 C21.7481039,16 22.8363165,16.0337513 23.428432,16.2531345 L23.1083692,18.2444592 C22.7883068,18.1432054 22.4202349,18.0757028 22.0841694,18.0757028 Z"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  components: {},
};
</script>

<style lang="scss" scoped></style>