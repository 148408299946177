<template>
 <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>ic_linkedin</title>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="01.48.coding-challange-1" transform="translate(-1346.000000, -3006.000000)" fill="#292929" fill-rule="nonzero">
            <g id="footer" transform="translate(-3.000000, 2885.000000)">
                <g id="Social" transform="translate(1168.000000, 108.000000)">
                    <g id="Social-Copy-2" transform="translate(168.000000, 0.000000)">
                        <g id="ic_linkedin" transform="translate(13.000000, 13.000000)">
                            <path d="M16,16 L16,10.14 C16,7.26 15.38,5.06 12.02,5.06 C10.4,5.06 9.32,5.94 8.88,6.78 L8.84,6.78 L8.84,5.32 L5.66,5.32 L5.66,16 L8.98,16 L8.98,10.7 C8.98,9.3 9.24,7.96 10.96,7.96 C12.66,7.96 12.68,9.54 12.68,10.78 L12.68,15.98 L16,15.98 L16,16 Z" id="Path"></path>
                            <polygon id="Path" points="0.26 5.32 3.58 5.32 3.58 16 0.26 16" fill="#FFFFFF"></polygon>
                            <path d="M1.92,0 C0.86,0 0,0.86 0,1.92 C0,2.98 0.86,3.86 1.92,3.86 C2.98,3.86 3.84,2.98 3.84,1.92 C3.84,0.86 2.98,0 1.92,0 Z" id="Path"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
</template>

<script>
export default {
  components: {},
};
</script>

<style lang="scss" scoped></style>