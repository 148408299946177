<template>
  <div class="footer-wrapper">
    <footer class="footer-wrapper-main">
      <div class="container">
        <div class="row justify-content-center">
          <div class="mb-5 text-center col-lg-5 col-md-12 col-12 mb-lg-0">
            <router-link
              to="/"
              class="mb-3 d-flex align-items-center justify-content-center link-dark text-decoration-none"
            >
              <img
                src="../../../../assets/images/logo-dark.svg"
                width="100"
                alt="squadio logo"
              />
            </router-link>
            <p>
              {{ $t("matchedTalents.squadioMessage") }}
            </p>
            <ul
              class="footer-wrapper-social-links d-flex justify-content-center"
            >
              <li class="d-flex align-items-center justify-content-center">
                <a href="https://www.facebook.com/joinsquadio/" target="_blank">
                  <FaceBook />
                </a>
              </li>
              <li class="d-flex align-items-center justify-content-center">
                <a href="https://twitter.com/joinsquadio" target="_blank">
                  <Twitter />
                </a>
              </li>
              <li class="d-flex align-items-center justify-content-center">
                <a
                  href=" https://www.instagram.com/joinsquadio"
                  target="_blank"
                >
                  <Instagram />
                </a>
              </li>
              <li class="d-flex align-items-center justify-content-center">
                <a
                  href="https://www.linkedin.com/company/join-squadio"
                  target="_blank"
                >
                  <Linkedin />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="text-center footer-wrapper-sub">
        <p class="mb-0">
          {{ $t("home.allRightsReserved") }}
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
import FaceBook from "../icons/facebook.vue";
import Twitter from "../icons/twitter.vue";
import Linkedin from "../icons/linkedin.vue";
import Instagram from "../icons/instagram.vue";
import "./footer.scss";
export default {
  components: { FaceBook, Twitter, Instagram, Linkedin },
};
</script>

<style lang="scss" scoped>
.footer-wrapper {
    margin-top: 30px;
}
</style>