<template>
  <svg
    width="16px"
    height="13px"
    viewBox="0 0 16 13"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Client"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="02.01.squadio2-final-design"
        transform="translate(-1233.000000, -4327.000000)"
        fill="#fff"
        fill-rule="nonzero"
      >
        <g id="footer" transform="translate(-3.000000, 4203.000000)">
          <g id="Outbound" transform="translate(1168.000000, 108.000000)">
            <g id="Vector" transform="translate(56.000000, 2.000000)">
              <path
                d="M26.1099997,16.0569999 C26.7899997,15.6509999 27.3090004,15.013 27.5530005,14.244 C26.9190004,14.6219999 26.2189996,14.8890001 25.4729996,15.0380001 C24.8709996,14.3970001 24.0129997,14 23.0769997,14 C21.2609997,14 19.7989998,15.4740001 19.7989998,17.2810001 C19.7989998,17.5410001 19.821,17.7909998 19.875,18.0289998 C17.148,17.8959998 14.735,16.5890001 13.114,14.598 C12.831,15.089 12.665,15.651 12.665,16.256 C12.665,17.3920001 13.25,18.3989999 14.122,18.9819999 C13.595,18.9719999 13.078,18.8190001 12.64,18.5780001 C12.64,18.5880001 12.64,18.6009998 12.64,18.6139998 C12.64,20.2079998 13.7769998,21.5319999 15.2679999,21.8369999 C15.0009999,21.9099999 14.71,21.9449997 14.408,21.9449997 C14.198,21.9449997 13.9859999,21.9329999 13.7869999,21.8889999 C14.212,23.188 15.4180002,24.1429998 16.8520002,24.1739998 C15.7360002,25.0469998 14.3190001,25.573 12.785,25.573 C12.516,25.573 12.258,25.5609999 12,25.5279999 C13.4529999,26.4649999 15.1750001,27 17.0320001,27 C23.0679998,27 26.368,22 26.368,17.6659999 C26.368,17.5209999 26.3629999,17.3810001 26.3559999,17.2420001 C27.007,16.7800001 27.554,16.203 28,15.539 C27.405,15.8 26.7709997,15.9729999 26.1099997,16.0569999 Z"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  components: {},
};
</script>

<style lang="scss" scoped></style>