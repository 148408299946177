<template>
  <div class="logedin-client-layout">
    <Navbar />
    <router-view></router-view>
    <Footer />
  </div>
</template>

<script>
import Navbar from "./components/navbar/navbar.vue";
import Footer from "./components/footer/footer.vue";
import "../Client/client.css";
import "../style.css";
import "./logedinClientLayout.scss";

export default {
  components: {
    Navbar,
    Footer,
  },
  data() {
    return {};
  },
};
</script>
